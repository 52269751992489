import React, { useState } from 'react'
import Logo from '../Assets/Images/LogoDark.svg'
import LogoDark from '../Assets/Images/Logo.svg'
import { useNavigate } from 'react-router-dom'
import { TiAttachment } from "react-icons/ti";
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { MdWhatsapp } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import { CModal, CModalBody } from '@coreui/react-pro';
import { IoClose, IoMenu } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import { URL } from '../URL';
import axios from 'axios';

const Contact = () => {
  const navigation = useNavigate()
  const [menu, setMenu] = useState(false)
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
    selectType: [],
  })
  const [document, setDocument] = useState([])
  const [visible, setVisible] = useState(false)
  const types = ['Branding', 'Logo design', 'Site from scratch', 'UI/UX', 'Motion Design', 'Packaging & Printing', 'HTML/CSS coding', 'Front-end development', 'Social media mangement', 'Other']

  const handleTypeSelect = (x) => {
    let array = [...form.selectType];
    let isDuplicate = false;
    for (let i = 0; i < array.length; i++) {
      const q = array[i];
      if (q === x) {
        isDuplicate = true;
        array.splice(i, 1);
        break;
      }
    }
    if (!isDuplicate) {
      array.push(x);
    }
    setForm(data => ({ ...data, selectType: array }));
  };


  const renderItem = (x, i) => {
    return (
      <div className='ContactLabel px-3 py-2' key={i}
        style={{
          backgroundColor: form.selectType.filter((y) => y === x).length > 0 ? '#823CA8' : '#ffffff',
          color: form.selectType.filter((y) => y === x).length > 0 ? '#ffffff' : '#0F0717'
        }}
        onClick={() => handleTypeSelect(x)}>
        {x}
      </div>
    )
  }

  const handleDocumentStore = (e) => {
    let file = []
    file.push(e)
    setDocument([...document, ...file])
  }

  const handleSubmit = () => {
    //   emailjs.sendForm("service_1kn9gvv", "template_tlmxjv7", formRef.current, "Kb--xW7sfeVtKPNUo" )
    //   .then((result) => {
    //     console.log(result.text);
    //     setVisible(true)
    // }, (error) => {
    //     console.log(error.text);
    // });
    
    if (form.email && form.message && form.name && (form.selectType.length > 0)) {
      setLoading(true);
      axios.post(`${URL}/tasks`, {
        name: form.name,
        email: form.email,
        message: form.message,
        category: form.selectType,
      }).then((response) => {
        if (response?.status === 200) {
          setVisible(true);
        }
      }).catch((e) => {
      console.log(e);
    }).finally(() => {
      setLoading(false);
    })
    }
  }

  const handleDelete = (x) => {
    const deletedArray = document.filter(data => data !== x)
    setDocument(deletedArray)
  }

  return (
    <div className='Contact d-flex flex-column align-items-center justify-content-center'>
      <div className='py-5 px-3 d-flex align-items-center justify-content-around w-100'>
        <div className='Logo-Bar-Contact w-100'>
          <img src={Logo} alt='Logo' className='Logo' />
          <div className='Desktop'>
            <div className='d-flex gap-3'>
              <div className='HeadBtn_Dark px-3 py-1' onClick={() => navigation('/')}>SERVICES</div>
              <div className='HeadBtn_Dark px-3 py-1' onClick={() => navigation('/work')}>WORK</div>
              <div className='HeadBtn_Dark_Active px-3 py-1' onClick={() => navigation('/contact')}>CONTACT</div>
            </div>
          </div>
          <IoMenu className='Mobile' style={{ cursor: 'pointer' }} size={24} color='#0F0717' onClick={() => setMenu(!menu)} />
        </div>
      </div>
      {menu ? (
        <div className='Mobile Menus-Box p-5 h-100' style={{ backgroundColor: '#0F0717', zIndex: 9999 }}>
          <div className='d-flex justify-content-between'>
            <img src={LogoDark} alt='LogoDark' className='Logo' />
            < IoClose style={{ cursor: 'pointer' }} size={24} color='#ffffff' onClick={() => setMenu(!menu)} />
          </div>
          <div className='Menus mt-3'>
            <div onClick={() => navigation('/')} className='ServiceBoxHead pb-5' style={{ color: '#ffffff' }}>Services</div>
            <div onClick={() => navigation('/work')} className='ServiceBoxHead pb-5' style={{ color: '#ffffff' }}>WORK</div>
            <div onClick={() => navigation('/contact')} className='ServiceBoxHead pb-5' style={{ color: '#823CA8' }}>Contact</div>
          </div>
          <div className='d-flex flex-column align-items-center gap-2 mt-3'>
            <div className='HeaderTxtSml' style={{ color: '#0F0717' }}>Contact Us</div>
            <div className='d-flex gap-3 align-items-center'>
              <a href={`https://wa.me/${+918667047680}`} target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="top" title="+91 86670 47680" rel="noreferrer"><MdWhatsapp /></a>
              <a href='https://www.instagram.com/dracdesign.in?igsh=M2k2NG5ta3hlam9z' target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="bottom" title="dracdesign.in" rel="noreferrer"><FaInstagram /></a>
              <a href='mailto:mukesh@dracdesign.in?' target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="top" title="hello@dracdesign.in" rel="noreferrer"><IoMdMail /></a>
            </div>
          </div>
        </div>
      ) : null}
      <div className='d-flex flex-column align-items-baseline justify-content-center gap-5 ContactWidth pb-5'>
        <div className='ContactTxt' style={{ opacity: 1 }}>Let's share your project with me. 😇</div>
        <div className='ContactHead' style={{ opacity: 0.8 }}>Get in touch</div>
        <div className='ContactTxt' style={{ opacity: 1 }}>What type of work you have?</div>
        <div className='d-flex flex-wrap align-items-baseline justify-conten-center gap-3 mb-5' style={{ opacity: 0.7 }}>
          {types.map((x, i) => (renderItem(x, i)))}
        </div>
        <div className='InputGrp' style={{ opacity: 0.7 }}>
          <input name='name' className='inputBar mb-5' placeholder='Your name' onChange={(e) => setForm(data => ({ ...data, name: e.target.value }))} value={form.name} />
          <input name='email' className='inputBar mb-5' placeholder='Your email' type='email' onChange={(e) => setForm(data => ({ ...data, email: e.target.value }))} value={form.email} />
        </div>
        <input name='message' className='col-12 inputArea mb-5' placeholder='About your project' type='text' style={{ opacity: 0.7 }} onChange={(e) => setForm(data => ({ ...data, message: e.target.value }))} value={form.message} />
        {document && <div> {document.map((x) => <div className='Document rounded p-2 mb-1'><div className='mr-5' style={{ width: '85%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{x}</div> <MdDeleteOutline size={24} onClick={() => handleDelete(x)} style={{ cursor: 'pointer' }} /></div>)} </div>}
        <div className='attachment d-flex justify-content-center align-items-center gap-3 py-3 px-3 mb-5' style={{ opacity: 0.7 }}>
          <TiAttachment size={30} />
          <label>Add attachment</label>
          <input className='attachmentInput align-self-start' type='file' onChange={(x) => handleDocumentStore(x.target.value)} />
        </div>
        <div className='TermsPolicy'>This site is protected by reCAPTCHA and the Google <span>Privacy Policy</span> and <span>Terms of Service</span> apply.</div>
        <div className='TalkBtn_Dark px-4 py-2 d-flex align-items-center align-self-center mb-5 mt-5' onClick={() => handleSubmit()}>
          <span>{loading ? 'Loading' :'Submit'}</span>
          <PiPaperPlaneRightFill />
        </div>
      </div>
      <CModal visible={visible} alignment="center" aria-labelledby="VerticallyCenteredExample">
        <CModalBody>
          <div className='d-flex flex-column justify-content-center align-items-center p-3'>
            <FaRegCircleCheck color='#0F0717' size={60} />
            <div className='ThanksTxt px-5 py-3'>Thanks we will contact you shortly</div>
            <div className='w-100 okTxt p-1' onClick={() => setVisible(false)}>OK</div>
          </div>
        </CModalBody>
      </CModal>
      {/* <div className='w-100 mt-5'>
        <img src={DesktopLine} alt='Line' className='w-100 Desktop' />
        <img src={MobileLine} alt='Line' className='w-100 Mobile' />
      </div> */}
      <div className='d-flex flex-column align-items-center justify-content-center gap-5 p-5 About w-100'>
        <div className='Footer w-75'>
          <div className='AboutHead'>Contact Us</div>
          <div className='d-flex gap-3 align-items-center'>
            <a href={`https://wa.me/${+918667047680}`} target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="top" title="+91 86670 47680" rel="noreferrer"><MdWhatsapp /></a>
            <a href='https://www.instagram.com/dracdesign.in?igsh=M2k2NG5ta3hlam9z' target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="bottom" title="dracdesign.in" rel="noreferrer"><FaInstagram /></a>
            <a href='mailto:mukesh@dracdesign.in?' target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="top" title="hello@dracdesign.in" rel="noreferrer"><IoMdMail /></a>
          </div>
        </div>
        <div className='BtmText mt-5'>2024 © dracdesign - All Rights Reserved</div>
      </div>
    </div>
  )
}

export default Contact