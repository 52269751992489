import React, { useState } from 'react'
import Box1Image from '../Assets/Images/Websit.svg'
import Box2Image from '../Assets/Images/Pasumai.svg'
import Box3Image from '../Assets/Images/Edtech.svg'
import Box4Image from '../Assets/Images/Vanam.svg'
import Logo from '../Assets/Images/LogoDark.svg'
import LogoDark from '../Assets/Images/Logo.svg'
import { useNavigate } from 'react-router-dom'
import { MdWhatsapp } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { IoClose, IoMenu } from "react-icons/io5";

const Work = () => {
    const navigation = useNavigate()
    const [menu, setMenu] = useState(false)
    const Box1 = {
        head: 'Website Landing ',
        body: 'Page Design',
        image: Box1Image,
        color: '#FFD7F9'
    }
    const Box2 = {
        head: 'Pasumai Nanban',
        body: 'Social Media + Logo Design',
        image: Box2Image,
        color: '#FFF7E0'
    }
    const Box3 = {
        head: 'Ed tech',
        body: 'Product landing page',
        image: Box3Image,
        color: '#CFF4ED'
    }
    const Box4 = {
        head: 'Vanam',
        body: 'Social Media + Logo Design',
        image: Box4Image,
        color: '#FED4D6'
    }
    return (
        <div className='Work'>
            <div className='p-5 d-flex align-items-center justify-content-around w-100'>
                <div className='Logo-Bar w-100'>
                    <img src={Logo} alt='Logo' className='Logo' />
                    <div className='Desktop'>
                        <div className='d-flex gap-3'>
                            <div className='HeadBtn_Dark px-3 py-1' onClick={() => navigation('/')}>SERVICES</div>
                            <div className='HeadBtn_Dark_Active px-3 py-1' onClick={() => navigation('/work')}>WORK</div>
                            <div className='HeadBtn_Dark px-3 py-1' onClick={() => navigation('/contact')}>CONTACT</div>
                        </div>
                    </div>
                    <IoMenu className='Mobile' style={{ cursor: 'pointer' }} size={24} color='#0F0717' onClick={() => setMenu(!menu)} />
                </div>
            </div>
            {menu ? (
                <div className='Mobile Menus-Box p-5 h-100' style={{ backgroundColor: '#0F0717', zIndex: 9999 }}>
                    <div className='d-flex justify-content-between'>
                        <img src={LogoDark} alt='LogoDark' className='Logo' />
                        < IoClose style={{ cursor: 'pointer' }} size={24} color='#ffffff' onClick={() => setMenu(!menu)} />
                    </div>
                    <div className='Menus mt-3'>
                        <div onClick={() => navigation('/')} className='ServiceBoxHead pb-5' style={{ color: '#ffffff' }}>Services</div>
                        <div onClick={() => navigation('/work')} className='ServiceBoxHead pb-5' style={{ color: '#823CA8' }}>Work</div>
                        <div onClick={() => navigation('/contact')} className='ServiceBoxHead pb-5' style={{ color: '#ffffff' }}>Contact</div>
                    </div>
                    <div className='d-flex flex-column align-items-center gap-2 mt-3'>
                        <div className='HeaderTxtSml' style={{ color: '#0F0717' }}>Contact Us</div>
                        <div className='d-flex gap-3 align-items-center'>
                            <a href={`https://wa.me/${+918667047680}`} target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="top" title="+91 86670 47680" rel="noreferrer"><MdWhatsapp /></a>
                            <a href='https://www.instagram.com/dracdesign.in?igsh=M2k2NG5ta3hlam9z' target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="bottom" title="dracdesign.in" rel="noreferrer"><FaInstagram /></a>
                            <a href='mailto:mukesh@dracdesign.in?' target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="top" title="hello@dracdesign.in" rel="noreferrer"><IoMdMail /></a>
                        </div>
                    </div>
                </div>
            ) : null}
            <div className='d-flex flex-column justify-content-center align-items-center gap-5 w-100 pb-5'>
                <div className='WorkHead'>Recent work</div>
                <div className='WorkCardBig rounded Desktop' style={{ backgroundColor: Box1.color }}>
                    <div className='ZoomOut p-5'>
                        <div className='WorkCardBigText'>
                            <div className='WorkBoxHead'>{Box1.head}</div>
                            <div className='WorkBoxBody'>{Box1.body}</div>
                        </div>
                        <div className='WorkCardBigImg'><img src={Box1.image} alt='BoxImage' className='w-100 WorkBoxImage' /></div>
                    </div>
                </div>
                <div className='Desktop col-9'>
                    <div className='WorkCardSmallRow'>
                        <div className='WorkCardSmall rounded' style={{ backgroundColor: Box2.color }}>
                            <div className='ZoomIn w-100 h-100 py-5 px-2 '>
                                <div className='WorkCardSmallText'>
                                    <div className='WorkBoxHead'>{Box2.head}</div>
                                    <div className='WorkBoxBody'>{Box2.body}</div>
                                </div>
                                <img img src={Box2.image} alt='BoxImage' className='w-100 WorkBoxImage' />
                            </div>
                        </div>
                        <div className='WorkCardSmall rounded' style={{ backgroundColor: Box3.color }}>
                            <div className='ZoomIn py-5 px-2 w-100 h-100 '>
                                <div className='WorkCardSmallText'>
                                    <div className='WorkBoxHead'>{Box3.head}</div>
                                    <div className='WorkBoxBody'>{Box3.body}</div>
                                </div>
                                <img img src={Box3.image} alt='BoxImage' className='w-100 WorkBoxImage' />
                            </div>
                        </div>
                        <div className='WorkCardSmall rounded' style={{ backgroundColor: Box4.color }}>
                            <div className='ZoomIn py-5 px-2 w-100 h-100 '>
                                <div className='WorkCardSmallText'>
                                    <div className='WorkBoxHead'>{Box4.head}</div>
                                    <div className='WorkBoxBody'>{Box4.body}</div>
                                </div>
                                <img img src={Box4.image} alt='BoxImage' className='w-100 WorkBoxImage' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Mobile w-100'>
                    <div className='MobileWorkBoxColumn'>
                        <div className='MobileWorkBox col-10 p-3 mb-5' style={{ backgroundColor: Box1.color }}>
                            <div className='pb-2 WorkBoxHead'>{Box1.head}</div>
                            <div className='pb-5 WorkBoxBody'>{Box1.body}</div>
                            <img src={Box1.image} alt='Box1Img' className='w-100' />
                        </div>
                        <div className='MobileWorkBox col-10 p-3 mb-5' style={{ backgroundColor: Box2.color }}>
                            <div className='pb-2 WorkBoxHead'>{Box2.head}</div>
                            <div className='pb-5 WorkBoxBody'>{Box2.body}</div>
                            <img src={Box2.image} alt='Box1Img' className='w-100' />
                        </div>
                        <div className='MobileWorkBox col-10 p-3 mb-5' style={{ backgroundColor: Box3.color }}>
                            <div className='pb-2 WorkBoxHead'>{Box3.head}</div>
                            <div className='pb-5 WorkBoxBody'>{Box3.body}</div>
                            <img src={Box3.image} alt='Box1Img' className='w-100' />
                        </div>
                        <div className='MobileWorkBox col-10 p-3' style={{ backgroundColor: Box4.color }}>
                            <div className='pb-2 WorkBoxHead'>{Box4.head}</div>
                            <div className='pb-5 WorkBoxBody'>{Box4.body}</div>
                            <img src={Box4.image} alt='Box1Img' className='w-100' />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='w-100'>
                <img src={DesktopLine} alt='Line' className='w-100 Desktop' />
                <img src={MobileLine} alt='Line' className='w-100 Mobile' />
            </div> */}
            <div className='d-flex flex-column align-items-center justify-content-center gap-5 p-5 About w-100'>
                <div className='Footer w-75'>
                    <div className='AboutHead'>Contact Us</div>
                    <div className='d-flex gap-3 align-items-center'>
                        <a href={`https://wa.me/${+918667047680}`} target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="top" title="+91 86670 47680" rel="noreferrer"><MdWhatsapp /></a>
                        <a href='https://www.instagram.com/dracdesign.in?igsh=M2k2NG5ta3hlam9z' target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="bottom" title="dracdesign.in" rel="noreferrer"><FaInstagram /></a>
                        <a href='mailto:mukesh@dracdesign.in?' target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="top" title="hello@dracdesign.in" rel="noreferrer"><IoMdMail /></a>
                    </div>
                </div>
                <div className='BtmText mt-5'>2024 © dracdesign - All Rights Reserved</div>
            </div>
        </div>
    )
}

export default Work