//*** Npm packages ***//
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

//*** Screens ***//
import Home from './Screens/Home'
import Work from './Screens/Work'
import Contact from './Screens/Contact'
import Admin from './Screens/Admin'

//*** Css ***//
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'aos/dist/aos.css';

const App = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' Component={Home} />
      <Route path='/work' Component={Work} />
      <Route path='/contact' Component={Contact} />
      <Route path='/Admin/Data/Admin/DarcDesign' Component={Admin} />
    </Routes>
    </BrowserRouter>
  )
}

export default App