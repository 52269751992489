import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { MdDelete } from "react-icons/md";
import { URL } from '../URL/index';


const Admin = () => {
  const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [allData, setAllData] = useState(true);

  useEffect(() => {
    getData();
  }, [])

  const getData = () => {
    // setLoading(true);
    axios.get(`${URL}/tasks`).then((response) => {
      if (response.status === 200) {
        setData(response?.data?.data);
        console.log(response);
      }
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      // setLoading(false);
    });
  }

  const handleDelete = (id) => {
    axios.delete(`${URL}/tasks/${id}`).then((response) => {
      if (response.status === 200) {
        getData();
      }
    }).catch((e) => {
      console.log(e)
    })
  }
  
  return (
     <div className='p-5'>
      <h1 className='d-flex justify-content-center mb-5'>Clients List</h1>
      <table className='table table-striped table-hover table-bordered border-primary text-center'>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Email</th>
            <th>Message</th>
            <th>Work Type</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {data.map((x, i) => (
            <tr key={x?._id}>
              <td>{i + 1}</td>
              <td>{x?.name}</td>
              <td>{x?.email}</td>
              <td>{x?.message}</td>
              <td>
                {x.category.map((y, j) => (
                <div>{j + 1}. {y}</div>
              ))}
              </td>
              <td onClick={() => handleDelete(x._id)}><MdDelete size={24} color='#f71634' style={{cursor: 'pointer'}} /></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Admin