//*** Npm packages ***//
import Aos from 'aos';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

//*** Reacr Icons ***//
import { HiFire } from "react-icons/hi";
import { MdWhatsapp } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { FaPuzzlePiece } from "react-icons/fa6";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { IoClose, IoMenu } from "react-icons/io5";

//*** Local Assets ***//
import Logo from '../Assets/Images/Logo.svg'
import LogoDark from '../Assets/Images/LogoDark.svg'
// import DesktopLine from '../Assets/Images/DesktopLine.svg'
// import MobileLine from '../Assets/Images/MobileLine.svg'
import Star from '../Assets/Images/Start.svg'
import Frame from '../Assets/Images/Frame.svg'
// import Rocket from '../Assets/Images/Rocket.svg';
import Pinacular from '../Assets/Images/Pinacular.svg'
import LogoDesign from '../Assets/Images/LogoDesign.svg'
import UiUxDesign from '../Assets/Images/UiUxDesign.svg'
import SocialMedia from '../Assets/Images/SocialMedia.svg'
import MotionDesign from '../Assets/Images/MotionDesign.svg'
import FrontEndDev from '../Assets/Images/FrontEndDevelopment.svg'


const Home = () => {
    const navigation = useNavigate()
    const [menu, setMenu] = useState(false)

    useEffect(() => {
        Aos.init()
    }, [])

    const scrollToServices = () => {
        const service = document.getElementById('serviceSection')
        service.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }

    const scrollToTop = () => {
        const top = document.getElementById('Top')
        top.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }
    return (
        <div className='w-100 Home'>
            <div className='Background_One w-100' id='Top'>
                <div className='Logo-Bar pb-5 w-100'>
                    <img src={Logo} alt='Logo' className='Logo' />
                    <div className='Desktop'>
                        <div className='d-flex gap-3'>
                            <div className='HeadBtn px-3 py-1' onClick={() => scrollToServices()}>SERVICES</div>
                            <div className='HeadBtn px-3 py-1' onClick={() => navigation('/work')}>WORK</div>
                            <div className='HeadBtn px-3 py-1' onClick={() => navigation('/contact')}>CONTACT</div>
                        </div>
                    </div>
                    <IoMenu className='Mobile' style={{ cursor: 'pointer' }} size={24} color='#ffffff' onClick={() => setMenu(!menu)} />
                </div>
                <div className='HeaderTxt  mb-3'>Innovative digital solutions for a brighter future</div>
                <div className='HeaderTxtSml pb-5'>Turning Concepts into Delightful Designs</div>
                <div className='TalkBtn_Dark px-4 py-2 d-flex align-items-center mb-3' onClick={() => navigation('contact')}>
                    <span>Lets Talk</span>
                    <PiPaperPlaneRightFill />
                </div>
            </div>
            {menu ? (
                <div className='Mobile Menus-Box p-5 h-100' style={{ backgroundColor: '#ffffff' }}>
                    <div className='d-flex justify-content-between'>
                        <img src={LogoDark} alt='LogoDark' className='Logo' />
                        <IoClose style={{ cursor: 'pointer' }} size={24} color='#0F0717' onClick={() => setMenu(!menu)} />
                    </div>
                    <div className='Menus mt-3'>
                        <div onClick={() => scrollToServices()} className='ServiceBoxHead pb-5' style={{ color: window.location.pathname === '/' ? '#823CA8' : '#0F0717' }}>Services</div>
                        <div onClick={() => navigation('/work')} className='ServiceBoxHead pb-5' style={{ color: window.location.pathname === '/work' ? '#823CA8' : '#0F0717' }}>WORK</div>
                        <div onClick={() => navigation('/contact')} className='ServiceBoxHead pb-5' style={{ color: window.location.pathname === '/contact' ? '#823CA8' : '#0F0717' }}>Contact</div>
                    </div>
                    <div className='d-flex flex-column align-items-center gap-2 mt-3'>
                        <div className='HeaderTxtSml' style={{ color: '#0F0717' }}>Contact Us</div>
                        <div className='d-flex gap-3 align-items-center'>
                            <a href={`https://wa.me/${+918667047680}`} target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="top" title="+91 86670 47680" rel="noreferrer"><MdWhatsapp /></a>
                            <a href='https://www.instagram.com/dracdesign.in?igsh=M2k2NG5ta3hlam9z' target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="bottom" title="dracdesign.in" rel="noreferrer"><FaInstagram /></a>
                            <a href='mailto:mukesh@dracdesign.in?' target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="top" title="hello@dracdesign.in" rel="noreferrer"><IoMdMail /></a>
                        </div>
                    </div>
                </div>
            ) : null}
            <div className='About d-flex flex-column justify-content-center align-items-center p-5 w-100'>
                <img src={Frame} alt='Frame' data-aos="slide-bottom" className='Mobile mb-3 img-sadow' />
                <div className='d-flex align-items-center gap-3'>
                    <div className='AboutHead'>What makes our process unique?</div>
                    <img src={Frame} alt='Frame' data-aos="slide-left" className='Desktop img-sadow' />
                </div>
                <div className='HeaderTxtSml'>More flexibility & productivity</div>
                <div className='col-12 About-Content py-5'>
                    <div className='AboutBox gap-3'>
                        <FaPuzzlePiece color='#823CA8' size={40} />
                        <div className='AboutBoxHead'>Design</div>
                        <div className='AboutBoxBody'> Designing visually stunning, unique digital interfaces that captivate and retain users, ensuring each project is 100% original, not templated.</div>
                    </div>
                    <div className='AboutBox gap-3'>
                        <HiFire color='#823CA8' size={40} />
                        <div className='AboutBoxHead'>Develop</div>
                        <div className='AboutBoxBody'>
                            Design approval is a crucial project milestone. Your consent propels us into development, transforming your vision into a tangible reality.</div>
                    </div>
                    <div className='AboutBox gap-3'>
                        <RiCheckboxCircleFill color='#823CA8' size={40} />
                        <div className='AboutBoxHead'>Deliver</div>
                        <div className='AboutBoxBody'>Deliver the finalized product, provide excellent user support, gather valuable feedback, ensuring client satisfaction and continuous improvement.</div>
                    </div>
                </div>
            </div>
            <div className='Service d-flex flex-column justify-content-center align-items-center w-100' id='serviceSection'>
                <div className='ServiceHead'>our services</div>
                <div className='Desktop'>
                    <div className='d-flex p-3 pb-5 mb-5'>
                        <img src={Star} alt='Star' />
                        <img src={Star} alt='Star' />
                    </div>
                </div>
                <div className='col-8 ServiceBox SearchBoxReverse'>
                    <div className='ServiceBox-Img'>
                        <img src={LogoDesign} alt='LogoDesign' className='w-100 img-sadow' data-aos="slide-right" />
                    </div>
                    <div className='ServiceBox-Text gap-4'>
                        <div className='ServiceBoxHead'>Logo Design</div>
                        <div className='ServiceBoxBody'>Establish your brand identity with a unique and memorable logo.</div>
                        <div className='TalkBtn_Light d-flex align-items-center mb-3' onClick={() => navigation('contact')}>
                            <span>Lets Talk</span>
                            <PiPaperPlaneRightFill />
                        </div>
                    </div>
                </div>
                <div className='col-8 ServiceBox'>
                    <div className='ServiceBox-Text gap-4'>
                        <div className='ServiceBoxHead'>UI/UX Design</div>
                        <div className='ServiceBoxBody'>Create an intuitive and visually pleasing user interface, ensuring a positive user experience.</div>
                        <div className='TalkBtn_Light px-5 py-2 d-flex align-items-center mb-3' onClick={() => navigation('contact')}>
                            <span>Lets Talk</span>
                            <PiPaperPlaneRightFill />
                        </div>
                    </div>
                    <div className='ServiceBox-Img'>
                        <img src={UiUxDesign} alt='UiUxDesign' className='w-100 img-sadow' data-aos="slide-left" />
                    </div>
                </div>
                <div className='col-8 ServiceBox SearchBoxReverse'>
                    <div className='ServiceBox-Img'>
                        <img src={FrontEndDev} alt='FrontEndDev' className='w-100 img-sadow' data-aos="slide-right" />
                    </div>
                    <div className='ServiceBox-Text gap-4'>
                        <div className='ServiceBoxHead'>Front-end Development</div>
                        <div className='ServiceBoxBody'>Translate your design into a functional and responsive front-end, bringing your vision to life.</div>
                        <div className='TalkBtn_Light px-5 py-2 d-flex align-items-center mb-3' onClick={() => navigation('contact')}>
                            <span>Lets Talk</span>
                            <PiPaperPlaneRightFill />
                        </div>
                    </div>
                </div>
                <div className='col-8 ServiceBox'>
                    <div className='ServiceBox-Text gap-4'>
                        <div className='ServiceBoxHead'>Motion Design</div>
                        <div className='ServiceBoxBody'>Enhance user engagement by incorporating dynamic elements and animations into your design.</div>
                        <div className='TalkBtn_Light px-5 py-2 d-flex align-items-center mb-3' onClick={() => navigation('contact')}>
                            <span>Lets Talk</span>
                            <PiPaperPlaneRightFill />
                        </div>
                    </div>
                    <div className='ServiceBox-Img'>
                        <img src={MotionDesign} alt='MotionDesign' className='w-100 img-sadow' data-aos="slide-left" />
                    </div>
                </div>
                <div className='col-8 ServiceBox SearchBoxReverse'>
                    <div className='ServiceBox-Img'>
                        <img src={SocialMedia} alt='SocialMedia' className='w-100 img-sadow' data-aos="slide-right" />
                    </div>
                    <div className='ServiceBox-Text gap-4'>
                        <div className='ServiceBoxHead'>Social Media</div>
                        <div className='ServiceBoxBody'>Promote your work, connect with your audience, and showcase your designs through various social media channels.</div>
                        <div className='TalkBtn_Light px-5 py-2 d-flex align-items-center mb-3' onClick={() => navigation('contact')}>
                            <span>Lets Talk</span>
                            <PiPaperPlaneRightFill />
                        </div>
                    </div>
                </div>
            </div>
            <div className='About p-0 w-100'></div>
            <div className='Background_Two px-5 pt-5 w-100'>
                <div className='Desktop' style={{ width: '20%' }}></div>
                <div className='d-flex flex-column align-items-center justify-content-center gap-3'>
                    <div className='ProjectHead'>Looking For Creative UI/UX Design?</div>
                    <div className='ProjectBody'>Let's discuss your design requirements and convert your vision into a real project together!</div>
                    <div className='TalkBtn_Dark px-4 py-2 d-flex align-items-center my-3' onClick={() => navigation('contact')}>
                        <span>Lets Talk</span>
                        <PiPaperPlaneRightFill />
                    </div>
                </div>
                <div className='Desktop' style={{ width: '20%' }} data-aos="slide-left">
                    <img src={Pinacular} alt='Pinacular' className='w-100 img-sadow' />
                </div>
                <div className='Mobile' style={{ width: '40%' }} data-aos="slide-right">
                        <img src={Pinacular} alt='Pinacular' className='w-100 img-sadow' />
                    </div>
            </div>
            <div className='About p-3 w-100'></div>
            {/* <div className='w-100 d-flex align-items-center justify-content-center'>
            <img src={Rocket} alt='Rocket' className='Rocket' />
            </div> */}
            {/* <div className='p-5 d-flex align-items-center justify-content-center Service w-100'>
                <img src={Star} alt='Star' className='StartImg' />
                <img src={Star} alt='Star' className='StartImg' />
                <div className='ThriveTxt'>Thrive in every stride</div>
                <img src={Star} alt='Star' className='StartImg' />
                <img src={Star} alt='Star' className='StartImg' />
            </div> */}
            <div className='w-100 BackToTap p-1' onClick={() => scrollToTop()}>
                {/* <img src={DesktopLine} alt='Line' className='w-100 Desktop'/>
                <img src={MobileLine} alt='Line' className='w-100 Mobile'/> */}
                Back to top
            </div>
            <div className='d-flex flex-column align-items-center justify-content-center gap-5 p-5 About w-100'>
                <div className='Footer w-75'>
                    <div className='AboutHead'>Contact Us</div>
                    <div className='d-flex gap-3 align-items-center'>
                        <a href={`https://wa.me/${+918667047680}`} target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="top" title="+91 86670 47680" rel="noreferrer"><MdWhatsapp /></a>
                        <a href='https://www.instagram.com/dracdesign.in?igsh=M2k2NG5ta3hlam9z' target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="bottom" title="dracdesign.in" rel="noreferrer"><FaInstagram /></a>
                        <a href='mailto:mukesh@dracdesign.in?' target='_blank' className='Rounded_Icon' data-toggle="tooltip" data-placement="top" title="hello@dracdesign.in" rel="noreferrer"><IoMdMail /></a>
                    </div>
                </div>
                <div className='BtmText mt-5'>2024 © Drac Design - All Rights Reserved</div>
            </div>
        </div>
    )
}

export default Home